import {
  Controller
} from '@hotwired/stimulus';

import {
  errorWithMessage
} from '@/custom/appsignal';

import * as bootstrap from 'bootstrap';

export default (function() {
  var _Class, failureCallback, provideCopyToClipboardFeedback;

  // Connects to data-controller="clipboard"
  _Class = class extends Controller {
    copy() {
      var copied_text, tooltip;
      copied_text = this.sourceTarget.getAttribute('value');
      tooltip = bootstrap.Tooltip.getOrCreateInstance(this.buttonTarget);
      return navigator.clipboard.writeText(copied_text).then(provideCopyToClipboardFeedback(this.element, tooltip), failureCallback);
    }

  };

  _Class.targets = ['button', 'source'];

  provideCopyToClipboardFeedback = function(element, tooltip) {
    var copyIcon, successIcon;
    copyIcon = element.querySelector('i:first-of-type');
    successIcon = element.querySelector('i:last-of-type');
    copyIcon.classList.add('d-none');
    successIcon.classList.remove('d-none');
    tooltip.show();
    return window.setTimeout((function() {
      tooltip.hide();
      copyIcon.classList.remove('d-none');
      return successIcon.classList.add('d-none');
    }), 2000); // 2 seconds
  };

  failureCallback = function(error) {
    return errorWithMessage(error, 'Could not copy text to clipboard');
  };

  return _Class;

}).call(this);
