import 'chartkick';

import Highcharts from 'highcharts/es-modules/masters/highcharts.src';

import 'highcharts/es-modules/masters/modules/accessibility.src.js';

import 'highcharts/es-modules/masters/modules/exporting.src.js';

import 'highcharts/es-modules/masters/modules/offline-exporting.src.js';

import 'highcharts/es-modules/masters/modules/current-date-indicator.src.js';

import 'highcharts/es-modules/masters/modules/timeline.src.js';

window.Highcharts = Highcharts;

document.addEventListener('turbo:load', function() {
  Highcharts.theme = {
    colors: ['var(--palette-color-alpha01)', 'var(--palette-color-alpha02)', 'var(--palette-color-alpha03)', 'var(--palette-color-alpha04)', 'var(--palette-color-alpha05)', 'var(--palette-color-alpha06)', 'var(--palette-color-alpha07)', 'var(--palette-color-alpha08)'],
    chart: {
      style: {
        fontFamily: 'Calibri, sans-serif',
        fontSize: '1rem'
      }
    },
    title: {
      margin: 25,
      style: {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: 1.2
      }
    },
    subtitle: {
      align: 'center',
      verticalAlign: 'bottom'
    },
    legend: {
      itemStyle: {
        fontSize: '0.85rem'
      }
    },
    lang: {
      decimalPoint: '.',
      thousandsSep: ','
    }
  };
  return Highcharts.setOptions(Highcharts.theme);
});
