import {
  Controller
} from '@hotwired/stimulus';

import * as bootstrap from 'bootstrap';

// Connects to data-controller="tooltip"
export default (class extends Controller {
  connect() {
    return this.tooltip = new bootstrap.Tooltip(this.element);
  }

  disconnect() {
    // As a workaround, set a timeout to allow "hide" events to get added to the Tooltip
    // before Stimulus attempts to dispose of the Tooltip
    // See https://github.com/twbs/bootstrap/issues/37474#issuecomment-1658773983
    return setTimeout((() => {
      var ref;
      return (ref = this.tooltip) != null ? ref.dispose() : void 0;
    }), 1000);
  }

});
