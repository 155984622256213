import {
  Controller
} from '@hotwired/stimulus';

import * as bootstrap from 'bootstrap';

// Connects to data-controller="popover"
export default (class extends Controller {
  connect() {
    return this.popover = new bootstrap.Popover(this.element);
  }

  disconnect() {
    // As a workaround, set a timeout to allow "hide" events to get added to the Popover
    // before Stimulus attempts to dispose of the Popover
    // See https://github.com/twbs/bootstrap/issues/37474#issuecomment-1658773983
    return setTimeout((() => {
      var ref;
      return (ref = this.popover) != null ? ref.dispose() : void 0;
    }), 1000);
  }

});
