var appsignal, errorWithMessage, key, metaTag, revision, revisionTag;

import Appsignal from '@appsignal/javascript';

import {
  plugin as windowEventsPlugin
} from '@appsignal/plugin-window-events';

metaTag = document.querySelector('meta[name="error-tracker-frontend-api-key"]');

key = metaTag != null ? metaTag.content : void 0;

revisionTag = document.querySelector('meta[name="error-tracker-revision"]');

revision = revisionTag != null ? revisionTag.content : void 0;

appsignal = new Appsignal({key, revision});

appsignal.use(windowEventsPlugin());

errorWithMessage = function(error, message) {
  return appsignal.sendError(error, {
    tags: {
      message: message
    }
  });
};

export {
  appsignal,
  errorWithMessage
};
